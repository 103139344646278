<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="Search" clearable> </el-date-picker>
              </el-form-item>
              <el-form-item label="类型">
                <el-select v-model="searchForm.type" style="width: 140px" @change="Search" size="small">
                  <el-option label="全部" value="-1"> </el-option>
                  <el-option label="机构" value="0"> </el-option>
                  <el-option label="平台" value="1"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="审方医生/联系方式" @keyup.enter.native="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <el-tabs v-model="activeTabs" @tab-click="handleClick">
          <el-tab-pane v-for="(tabs, index) in itemOption" :key="index" :label="tabs.label" :name="tabs.value">
            <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
              <el-table-column type="index" label="编号" width="50"></el-table-column>
              <el-table-column prop="auditUserName" label="审方医生"></el-table-column>
              <el-table-column prop="auditUserPhone" label="联系方式" align="center"> </el-table-column>
              <el-table-column prop="auditUserNum" label="审方数量" align="center"></el-table-column>
              <el-table-column prop="auditMoney" label="总金额（元）" align="center">
                <template slot-scope="scope">
                  <p>{{ scope.row.auditMoney }}</p>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" align="center" width="150px">
                <template slot-scope="scope">
                  <span class="primary optionBtn" @click="prviewBtn(scope.row)">明细</span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!--    查看    -->
    <el-dialog :visible.sync="infoDialog" width="55%" @close="UserhandleClose">
      <div class="table-top">
        <div class="iq-search-bar">
          <el-form :model="searchForm2" :inline="true">
            <el-form-item>
              <el-date-picker v-model="searchForm2.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="SearchUser" clearable> </el-date-picker>
            </el-form-item>
            <el-form-item label="">
              <el-input v-model="searchForm2.keyWord" type="text" size="small" placeholder="审方医生/联系方式" @keyup.enter.native="SearchUser" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="SearchUser()">搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-table :data="infoData" stripe style="width: 100%" height="440px">
        <el-table-column type="index" label="编号" width="50"></el-table-column>
        <el-table-column prop="orderNum" label="订单编号" />
        <el-table-column prop="doctorName" label="接诊医生" align="center" />
        <el-table-column prop="doctorPhone" label="联系方式" align="center" />
        <el-table-column prop="departmentName" label="所属部门" align="center" />
        <el-table-column prop="totalPayMoney" label="订单总额(元)" align="center" />
        <el-table-column prop="auditMoney" label="审方费用(元)" align="center" />
        <el-table-column prop="paySuccessTime" label="收费时间" align="center" width="160px">
          <template slot-scope="scope">
            {{ scope.row.paySuccessTime | timefilters }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="changePage2" :page-size="pageSize2" :current-page="pageIndex2" :total="dataTotal2" layout="total, prev, pager, next, jumper"></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient.js'
import { Department } from '@/components/HospitalDomain/Department.js'
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    var department = new Department(this.TokenClient, this.Services.Authorization)
    return {
      OutpatientDomain: outpatient,
      Department: department,
      headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      departmentData: [],
      searchForm: {
        keyWord: '',
        departmentId: 0,
        datepicker: '',
        type: '-1',
      },
      infoData: [],
      infoDialog: false,
      pageIndex2: 1,
      dataTotal2: 1,
      pageSize2: 0,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      searchForm2: {
        keyWord: '',
        datepicker: '',
      },
      pageIndex2: 1,
      dataTotal2: 1,
      pageSize2: 0,
      activeTabs: '0',
      itemOption: [
        {
          value: '0',
          label: '审方费',
        },
        {
          value: '1',
          label: '药剂师审方费',
        },
      ],
    }
  },
  mounted() {
    // this.getDepartment()
    this.getList()
  },
  methods: {
    UserhandleClose() {
      this.pageIndex2 = 1
      this.dataTotal2 = 0
      this.searchForm2.keyWord = ''
    },
    getDetail() {
      var _this = this
      _this.infoData = []
      var item = _this.searchForm2
      if (_this.searchForm2.datepicker) {
        item.startTime = _this.searchForm2.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm2.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      if (this.activeTabs == '0') {
        _this.OutpatientDomain.MZAuditInputInfo(
          item.keyWord,
          item.departmentId,
          this.id,
          item.startTime,
          item.endTime,
          this.pageIndex2,
          function (data) {
            _this.infoData = data.data.results
            _this.pageIndex2 = data.data.pageIndex
            _this.pageSize2 = data.data.pageSize
            _this.dataTotal2 = data.data.dataTotal
          },
          function (err) {
            console.log(err)
          }
        )
      }else{
        _this.OutpatientDomain.MZAuditPharmacistInputInfo(
          item.keyWord,
          item.departmentId,
          this.id,
          item.startTime,
          item.endTime,
          this.pageIndex2,
          function (data) {
            _this.infoData = data.data.results
            _this.pageIndex2 = data.data.pageIndex
            _this.pageSize2 = data.data.pageSize
            _this.dataTotal2 = data.data.dataTotal
          },
          function (err) {
            console.log(err)
          }
        )
      }
    },
    changePage2(pageIndex) {
      this.pageIndex2 = pageIndex
      this.getDetail()
    },
    prviewBtn(item) {
      this.id = item.auditUserId
      this.infoDialog = true
      this.getDetail()
    },
    handleOption(e) {
      this.searchForm.departmentId = e[0]
      this.pageIndex = 1
      this.getList()
    },
    getDepartment() {
      let orgId = JSON.parse(sessionStorage.getItem('userInfo')).organizationId
      var _this = this
      _this.Department.OrgDepartment(
        orgId,
        function (data) {
          _this.departmentData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    handleClick(row) {
      this.pageIndex=1
      this.getList()
    },
    getList() {
      var _this = this
      _this.tableData = []
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.OutpatientDomain.MZAuditPerformanceInfo(
        item.keyWord,
        item.startTime,
        item.endTime,
        this.pageIndex,
        parseInt(item.type),
        this.activeTabs=='0'?false:true,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search(event) {
      this.pageIndex = 1
      this.getList()
    },
    SearchUser(event) {
      this.pageIndex2 = 1
      this.getDetail()
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
